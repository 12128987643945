<template>
  <div>
    <el-dialog
      title="编辑内容"
      :visible.sync="openDialog"
      width="850px"
      @open="openDialogFn"
      :show-close="true"
      class="dialog-vertical"
      @close="cancel('ruleData')"
      modal-append-to-body
    >
      <el-form
        class="medium-form"
        size="medium"
        :model="dataContent"
        :rules="rules"
        ref="ruleData"
        label-width="80px"
        v-loading="loading"
      >
        <el-form-item label="标题：" prop="title">
          <el-input :disabled="currentRow.editable===0" v-model="dataContent.title"></el-input>
        </el-form-item>
        <el-form-item label="会内职务：">
          <el-select
            @change="changePositionId"
            v-model="position_id"
            placeholder="请选择会内职务"
          >
            <el-option
              v-for="item in positionOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!--已编辑岗位：  -->
          <div class="imgTips">{{ this.isEditText }}</div>
        </el-form-item>
        <el-form-item
          v-if="position_id && taskArr.length"
          label="内容："
          prop="content"
        >
          <tinymce
            id="tinymce-editor"
            @choose="handleToolBarClick"
            v-model="taskArr[taskI].content"
            show-article-import
            :height="700"
          ></tinymce>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="openDialog = false">取消</el-button>
        <el-button
          type="primary"
          @click="submit('ruleData')"
          :loading="saveLoading"
          >保存</el-button
        >
      </span>
    </el-dialog>
    <!-- 富文本插入图片 -->
    <media-selector v-model="showImageList" multiple @select="updateImages" />
    <media-selector
      v-model="showVideoList"
      type="video"
      multiple
      @select="updateVideos"
    />
    <media-selector
      v-model="showAudioList"
      type="audio"
      multiple
      @select="updateAudios"
    />
    <!-- 文章导入 -->
    <import-article v-model="isShowImportDialog" @confirm="getImportDetail" />
    <!-- 导入HTML -->
    <import-html-code v-model="isShowHtmlInput" />
  </div>
</template>

<script>
import {
  saveContent,
  contentDetail,
  positionContentDetail,
  savePositionContent,
  getOrganizationPositions,
} from "../../api/org-frame/add-frame";
import Tinymce from "@/base/components/Editor/Tinymce";

import MediaSelector from "../../../common/components/MediaSelector";
import ImportArticle from "@/base/components/Editor/ImportArticle";
import ImportHtmlCode from "@/base/components/Editor/ImportHtmlCode";
export default {
  components: { Tinymce, MediaSelector, ImportArticle, ImportHtmlCode },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    currentRow: {
      type: Object,
      required: true,
    },
    organization_id: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      saveLoading: false,
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "change" }],
      },
      //   页面内容
      dataContent: {
        id: "",
        organization_id: "",
        title: "",
        content: "",
        editable: 0,
      },
      // 选中的职务id
      position_id: "",
      // 职务选择项
      positionOptions: [],
      // 循环保存数组
      taskArr: [],
      taskI: 0,
      // 媒体库图片弹窗
      showImageList: false,
      showVideoList: false,
      showAudioList: false,
      isShowImportDialog: false,
      isShowHtmlInput: false,
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          // 任务请求数组
          let task = [];
          this.taskArr.forEach((item) => {
            task.push(this.saveMember(item));
          });
          Promise.all(task).then((res) => {
            // 保存内容
            saveContent(this.dataContent)
              .then((res) => {
                this.$message.success(res.msg);
                if (this.dataContent.id) {
                  this.$emit("updateContent", this.dataContent);
                }
                this.saveLoading = false;
                this.cancel();
              })
              .catch((err) => {
                this.saveLoading = false;
              });
          });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      // 选中的职务id
      this.position_id = "";
      // 职务选择项
      this.positionOptions = [];
      // 循环保存数组
      this.taskArr = [];
      this.taskI = 0;
    },
    // 打开弹窗
    openDialogFn() {
      if (this.$props.currentRow.id) {
        this.getContentDetail();
        this.getPositionsOptions();
      } else {
        this.dataContent = this.$props.currentRow;
      }
    },
    // 获取页面内容
    getContentDetail() {
      this.loading = true;
      contentDetail({ id: this.$props.currentRow.id })
        .then((res) => {
          this.dataContent = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 获取组织下的职务
    getPositionsOptions() {
      getOrganizationPositions({
        organization_id: this.$props.organization_id,
      }).then((res) => {
        this.positionOptions = res.data;
        this.positionOptions.forEach(async (item) => {
          await this.setTaskArr(item);
        });
        console.log(this.taskArr);
      });
    },
    // 富文本点击事件
    handleToolBarClick(e) {
      switch (e.type) {
        case "image":
          this.showImageList = true;
          break;
        case "video":
          this.showVideoList = true;
          break;
        case "audio":
          this.showAudioList = true;
          break;
        case "import":
          // this.isShowImportDialog = true;
          break;
        case "html":
          this.isShowHtmlInput = true;
          break;
      }
    },
    // ------富文本相关
    updateImages(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<img src="${item.url}"/>`
        );
      });
    },
    updateVideos(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<p><span class="mce-preview-object mce-object-video"
                contenteditable="false"
                data-mce-object="video"
                data-mce-p-controls="controls"
                data-mce-p-controlslist="nodownload"
                data-mce-p-allowfullscreen="true"
                data-mce-p-frameborder="no"
                data-mce-p-scrolling="no"
                data-mce-html=""
                data-mce-p-src="${item.url}">
                <video controls src="${item.url}"></video></span></p>
                <p><br/></p>`
          // `<video poster="${item.config.thumbnail}" controls src="${item.url}"></video>`
        );
      });
    },
    updateAudios(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<p><span class="mce-object-audio"
            contenteditable="false"
            data-mce-object="audio"
            data-mce-p-controls="controls"
            data-mce-p-controlslist="nodownload"
            data-mce-p-frameborder="no"
            data-mce-p-scrolling="no"
            data-mce-html=""
            data-mce-p-src="${item.url}">
            <audio controls src="${item.url}"></audio></span></p>
            <p><br/></p>`
        );
      });
    },
    // 导入公众号文章内容
    getImportDetail(e) {
      console.log(this.form);
      this.$set(this.form, "content", e.content);
      this.$refs.editor.$emit("change", e.content);
    },
    // 切换职务显示对应内容
    changePositionId(pid) {
      this.taskI = this.taskArr.findIndex((item) => item.pid === pid);
    },
    setTaskArr(item) {
      positionContentDetail({
        content_id: this.$props.currentRow.id,
        position_id: item.id,
      }).then((res) => {
        this.taskArr.push({
          pid: item.id,
          content: res.data,
          name: item.name,
        });
      });
    },
    saveMember(item) {
      // 保存内容
      return savePositionContent({
        content_id: this.dataContent.id,
        position_id: item.pid,
        content: item.content,
      });
    },
  },
  watch: {},
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    // 已编辑岗位提示文字
    isEditText() {
      console.log(this.taskArr);
      let isEditArry = this.taskArr.filter((item) => item.content);
      isEditArry = isEditArry.map((item) => {
        return item.name;
      });
      console.log(isEditArry);
      if (isEditArry.length) {
        return `已编辑职务：${isEditArry.join("、")}`;
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss">
.imgTips {
  color: #909399;
  font-size: 14px;
}
</style>
