<template>
  <div>
    <el-dialog
      title="编辑内容"
      :visible.sync="openDialog"
      width="850px"
      @open="openDialogFn"
      :show-close="true"
      class="dialog-vertical"
      @close="cancel('ruleData')"
      modal-append-to-body
    >
      <el-form
        class="medium-form"
        size="medium"
        :model="dataContent"
        :rules="rules"
        ref="ruleData"
        label-width="80px"
        v-loading="loading"
      >
        <el-form-item label="标题：" prop="title">
          <el-input v-model="dataContent.title"></el-input>
        </el-form-item>
        <el-form-item label="内容：" prop="content">
          <tinymce
            id="tinymce-editor"
            @choose="handleToolBarClick"
            v-model="dataContent.content"
            show-article-import
            :height="600"
          ></tinymce>
        </el-form-item>
      </el-form>

      <span slot="footer">
        <el-button @click="openDialog = false">取消</el-button>
        <el-button
          type="primary"
          @click="submit('ruleData')"
          :loading="saveLoading"
          >确定</el-button
        >
      </span>
    </el-dialog>
    <!-- 富文本插入图片 -->
    <media-selector v-model="showImageList" multiple @select="updateImages" />
    <media-selector
      v-model="showVideoList"
      type="video"
      multiple
      @select="updateVideos"
    />
    <media-selector
      v-model="showAudioList"
      type="audio"
      multiple
      @select="updateAudios"
    />
    <!-- 文章导入 -->
    <import-article v-model="isShowImportDialog" @confirm="getImportDetail" />
    <!-- 导入HTML -->
    <import-html-code v-model="isShowHtmlInput" />
  </div>
</template>

<script>
import {
  saveContent,
  contentDetail,
  positionContentDetail,
  savePositionContent,
} from "../../api/org-frame/add-frame";
import Tinymce from "@/base/components/Editor/Tinymce";

import MediaSelector from "../../../common/components/MediaSelector";
import ImportArticle from "@/base/components/Editor/ImportArticle";
import ImportHtmlCode from "@/base/components/Editor/ImportHtmlCode";
export default {
  components: { Tinymce, MediaSelector, ImportArticle, ImportHtmlCode },
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    currentRow: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      saveLoading: false,
      rules: {
        title: [{ required: true, message: "请输入标题", trigger: "change" }],
        content: [{ required: true, message: "请输入内容", trigger: "change" }],
      },
      //   页面内容
      dataContent: {
        id: "",
        organization_id: "",
        title: "",
        content: "",
      },
      // 媒体库图片弹窗
      showImageList: false,
      showVideoList: false,
      showAudioList: false,
      isShowImportDialog: false,
      isShowHtmlInput: false,
    };
  },
  methods: {
    // 保存
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          // 保存内容
          saveContent({...this.dataContent})
            .then((res) => {
              this.$message.success(res.msg);
              if (this.dataContent.id) {
                this.$emit("updateContent", this.dataContent);
              } else {
                this.dataContent.id = res.data;
                let { id, title, status } = this.dataContent;
                this.$emit("addContent", { id, title, status });
              }

              this.saveLoading = false;
              this.cancel();
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
      // if (!this.postForm.id) {
      //   this.$refs[formName].resetFields();
      // }
    },
    // 打开弹窗
    openDialogFn() {
      console.log(this.$props.currentRow);
      if (this.$props.currentRow.id) {
        this.getContentDetail();
      } else {
        this.dataContent = this.$props.currentRow;
      }
    },
    // 获取页面内容
    getContentDetail() {
      this.loading = true;
      contentDetail({ id: this.$props.currentRow.id })
        .then((res) => {
          this.dataContent = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 富文本点击事件
    handleToolBarClick(e) {
      switch (e.type) {
        case "image":
          this.showImageList = true;
          break;
        case "video":
          this.showVideoList = true;
          break;
        case "audio":
          this.showAudioList = true;
          break;
        case "import":
          // this.isShowImportDialog = true;
          break;
        case "html":
          this.isShowHtmlInput = true;
          break;
      }
    },
    // ------富文本相关
    updateImages(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<img src="${item.url}"/>`
        );
      });
    },
    updateVideos(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<p><span class="mce-preview-object mce-object-video"
                contenteditable="false"
                data-mce-object="video"
                data-mce-p-controls="controls"
                data-mce-p-controlslist="nodownload"
                data-mce-p-allowfullscreen="true"
                data-mce-p-frameborder="no"
                data-mce-p-scrolling="no"
                data-mce-html=""
                data-mce-p-src="${item.url}">
                <video controls src="${item.url}"></video></span></p>
                <p><br/></p>`
          // `<video poster="${item.config.thumbnail}" controls src="${item.url}"></video>`
        );
      });
    },
    updateAudios(e) {
      e.forEach((item) => {
        window.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<p><span class="mce-object-audio"
            contenteditable="false"
            data-mce-object="audio"
            data-mce-p-controls="controls"
            data-mce-p-controlslist="nodownload"
            data-mce-p-frameborder="no"
            data-mce-p-scrolling="no"
            data-mce-html=""
            data-mce-p-src="${item.url}">
            <audio controls src="${item.url}"></audio></span></p>
            <p><br/></p>`
        );
      });
    },
    // 导入公众号文章内容
    getImportDetail(e) {
      console.log(this.form);
      this.$set(this.form, "content", e.content);
      this.$refs.editor.$emit("change", e.content);
    },
  },
  watch: {},
  computed: {
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
  },
};
</script>

<style lang="scss"></style>
