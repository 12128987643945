import api from "@/base/utils/request";

// 获取架构详情
export const OrganizationDetail = (data) => {
  return api({
    url: "/admin/cyc/Organization/detail",
    method: "post",
    data,
  });
};

// 获取上级组织列表
export const ParentList = (data) => {
  return api({
    url: "/admin/cyc/Organization/OrganizationsTree",
    method: "post",
    data,
  });
};

// 保存组织架构
export const SaveOrganization = (data) => {
  return api({
    url: "/admin/cyc/Organization/save",
    method: "post",
    data,
  });
};

// 获取申请模板
export const JoinModules = (data) => {
  return api({
    url: "/admin/cyc/Join/index",
    method: "post",
    data,
  });
};

// 保存申请模板
export const HandleSave = (data) => {
  return api({
    url: "/admin/cyc/Join/save",
    method: "post",
    data,
  });
};

export const getRoleSetting = (data) => {
  return api({
    url: "/admin/cyc/settings/getCycApplyRoleConfig",
    method: "post",
    data,
  });
};
//  保存入会须知详情
export const saveInstructions = (data) => {
  return api({
    url: "/admin/cyc/Organization/saveInstructions",
    method: "post",
    data,
  });
};
//  获取入会须知详情
export const instructionsDetail = (data) => {
  return api({
    url: "/admin/cyc/Organization/instructionsDetail",
    method: "post",
    data,
  });
};
//  保存页面内容
export const saveContent = (data) => {
  return api({
    url: "/admin/cyc/Organization/saveContent",
    method: "post",
    data,
  });
};
//  页面内容详情
export const contentDetail = (data) => {
  return api({
    url: "/admin/cyc/Organization/contentDetail",
    method: "post",
    data,
  });
};
//  获取会员权益的职务页面内容
export const positionContentDetail = (data) => {
  return api({
    url: "/admin/cyc/Organization/positionContentDetail",
    method: "post",
    data,
    notCancel: true
    
  });
};
//  保存会员权益的职务页面内容
export const savePositionContent = (data) => {
  return api({
    url: "/admin/cyc/Organization/savePositionContent",
    method: "post",
    data,
    notCancel: true

  });
};
//  获取组织下的职务
export const getOrganizationPositions= (data) => {
  return api({
    url: "/admin/cyc/organization/positions",
    method: "post",
    data,
  });
};

// 还原架构配置
export const resetGlobal = (data) => {
  return api({url:'/admin/cyc/apply_template/restoreTemplate', method: 'post', data})
}