<template>
  <div class="add-subjects">
    <el-button
      class="subject-add-btn"
      type="primary"
      size="small"
      @click="addLine"
      >添加</el-button
    >

    <div class="subject-list" v-show="myValue.length !== 0">
      <draggable v-model="myValue" animation="500" draggable=".draggable">
        <div
          class="single-subject draggable"
          v-for="(item, index) in myValue"
          :key="item.id + index"
        >
          <div class="item flex">
            <div class="item-input">
              <img src="../../assets/images/operation.png" alt="" />
              <p>{{ item.title }}</p>
              <div class="isHide">
                <span
                  @click="changeStatus(index, 1)"
                  :class="[item.status ? 'active' : '']"
                  >显示</span
                >
                /
                <span
                  @click="changeStatus(index, 0)"
                  :class="[item.status ? '' : 'active']"
                  >不显示</span
                >
              </div>
              <el-button @click="myEdit(index)" type="text">
                <i class="el-icon-edit"></i>
              </el-button>
              <el-button type="text" @click="delet(index, item)">
                <i class="el-icon-delete"></i>
              </el-button>
            </div>
          </div>
        </div>
      </draggable>
    </div>
    <EditNoticeContent
      v-model="openEditNotice"
      :currentRow="currentRow"
      @updateContent="updateContent"
      @addContent="addContent"
    ></EditNoticeContent>
    <EditMemberContent
      v-model="openEditMember"
      :currentRow="currentRow"
      :organization_id="organization_id"
      @updateContent="updateContent"
      @addContent="addContent"
    ></EditMemberContent>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import EditNoticeContent from "./EditNoticeContent";
import EditMemberContent from "./EditMemberContent";
export default {
  model: {
    prop: "value",
    event: "handleValue",
  },
  props: {
    // 快捷捐款数组
    value: {
      type: Array,
    },
    // 组织id
    organization_id: {
      type: String,
    },
  },
  components: { draggable, EditNoticeContent, EditMemberContent },
  data() {
    return {
      loading: true,
      subjects: [],
      currentRow: {},
      currentI: 0,
      // 修改页面内容弹窗
      openEditNotice: false,
      // 修改页面内容 会员权益
      openEditMember: false,
    };
  },

  computed: {
    // 快捷捐款数组
    myValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("handleValue", val);
      },
    },
  },
  created() {
    console.log(this.myValue);
  },
  methods: {
    // 移除
    delet(i, item) {
      if (!item.editable) {
        this.$message.info("会员权益不可删除");
        return;
      }
      this.$msgbox
        .confirm("确定是否删除", "提示", {
          type: "error",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        })
        .then((res) => {
          this.myValue.splice(i, 1);
        })
        .catch((err) => {});
    },
    // 添加
    addLine() {
      this.currentRow = {
        id: "",
        organization_id: this.$props.organization_id,
        content: "",
        title: "",
        status: 1,
      };
      this.openEditNotice = true;
    },
    picker() {
      console.log(111);
    },
    // 编辑内容名称
    myEdit(i) {
      this.currentRow = this.myValue[i];
      this.currentI = i;
      if (this.currentRow.editable) {
        // 普通修改页面内容
        this.openEditNotice = true;
      } else {
        // 修改会员权益
        this.openEditMember = true;
      }
    },
    // 改变显示隐藏
    changeStatus(index, status) {
      this.myValue[index].status = status;
    },
    // 更新内容标题
    updateContent(content) {
      // 更新标题
      this.$set(this.myValue[this.currentI], "title", content.title);
    },
    // 添加新内容
    addContent(content) {
      console.log(content, this.myValue);
      this.myValue.push(content);
    },
  },
};
</script>

<style lang="scss" scoped>
.subject-add-btn {
  margin-bottom: 10px;
}
.add-subjects {
  width: 450px;
  .subject-list {
    display: flex;
    flex-direction: column;
    background-color: #f5f7fa;
    justify-content: center;
    align-items: center;
    padding: 20px;
    .single-subject {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .item {
        background-color: #fff;

        padding: 10px;

        margin: 5px 0;
        // background-color: #f4f3f4;
        border-radius: 5px;
        .item-input {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 12px;
            height: 12px;
          }
          p {
            width: 190px;
            margin-left: 10px;
          }
          .isHide {
            margin-right: 20px;
            color: #b3b3b3;

            span {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.el-icon-delete {
  color: #b3b3b3;
}
.el-icon-edit {
  color: #b3b3b3;
}
.el-icon-delete:hover {
  color: #ff4949;
}
.el-icon-edit:hover {
  color: #3576ff;
}
.active {
  color: #3576ff !important;
}
</style>
