<template>
  <div class="body" v-loading="loading">
    <div class="step-box">
      <el-steps :active="stepStatus" align-center>
        <el-step v-for="(item, i) in onLine" :key="i">
          <template #title>
            <div class="step-title" @click="changeStatus(i)">
              {{ item.name }}
            </div>
          </template>
          <template #icon>
            <div class="step-icon" @click="changeStatus(i)"></div>
          </template>
        </el-step>
      </el-steps>
    </div>
    <!--  架构信息-->
    <template v-if="stepStatus === 0">
      <el-form
        size="medium"
        :model="frameForm"
        :rules="rules"
        label-position="right"
        label-width="90px"
        class="medium-form"
        ref="frameForm"
        v-if="frameForm"
        @submit.native.prevent
      >
        <el-form-item
          v-if="tenantVersion !== 'school'"
          label="架构名称："
          prop="name"
        >
          <el-input
            type="text"
            v-model="frameForm.name"
            placeholder="请输入架构名称"
          ></el-input>
        </el-form-item>
        <el-form-item v-else label="校友会名称：" prop="name">
          <el-input
            type="text"
            v-model="frameForm.name"
            placeholder="请输入内容"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="tenantVersion !== 'school'"
          label="上级架构："
          prop="ids"
        >
          <el-cascader
            ref="CascaderRef"
            v-model="frameForm.ids"
            :options="parentOptions"
            @change="handleExportCatalogChange"
            :props="{
              value: 'id',
              label: 'name',
              checkStrictly: true,
              expandTrigger: 'hover',
            }"
            :placeholder="frameForm.id ? frameForm.pname : '请选择上级架构'"
            filterable
          ></el-cascader>
        </el-form-item>
        <el-form-item v-else label="上级：" prop="ids">
          <el-cascader
            ref="CascaderRef"
            v-model="frameForm.ids"
            :options="parentOptions"
            @change="handleExportCatalogChange"
            :props="{
              value: 'id',
              label: 'name',
              checkStrictly: true,
              expandTrigger: 'hover',
            }"
            :placeholder="frameForm.id ? frameForm.pname : '请选择上级'"
            filterable
          ></el-cascader>
        </el-form-item>
        <el-form-item
          v-if="tenantVersion !== 'school'"
          label="图标："
          prop="icon"
        >
          <SingleMediaWall
            addText="上传图标"
            v-model="frameForm.icon"
            :height="104"
            :width="104"
            ratio="96:96"
          >
            <div slot="info" class="image-info">建议尺寸 96*96px</div>
          </SingleMediaWall>
          <el-input v-show="false" v-model="frameForm.icon" />
        </el-form-item>
        <el-form-item v-else label="Logo：" prop="icon">
          <SingleMediaWall
            addText="上传 Logo"
            v-model="frameForm.icon"
            :height="104"
            :width="104"
            ratio="96:96"
          >
            <div slot="info" class="image-info">建议尺寸 96*96px</div>
          </SingleMediaWall>
          <el-input v-show="false" v-model="frameForm.icon" />
        </el-form-item>
        <el-form-item label="描述：" prop="description">
          <el-input
            type="text"
            v-model="frameForm.description"
            maxlength="18"
            show-word-limit
            placeholder="请输入描述"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="tenantVersion !== 'school'"
          label="申请状态："
          prop="is_open_join_template"
        >
          <el-radio v-model="frameForm.is_open_join_template" :label="1"
            >允许用户提交申请</el-radio
          >
          <el-radio v-model="frameForm.is_open_join_template" :label="0"
            >用户不可提交申请</el-radio
          >
          <div class="form-tip">
            <span
              class="el-icon-info"
              style="font-size: 15px; color: #c0c4cc"
            ></span>
            <span
              >如勾选允许用户提交申请，则可以配置申请信息、预备会员任务内容</span
            >
          </div>
        </el-form-item>
        <el-form-item v-else label="认证状态：" prop="is_open_join_template">
          <el-radio v-model="frameForm.is_open_join_template" :label="1"
            >开启认证申请</el-radio
          >
          <el-radio v-model="frameForm.is_open_join_template" :label="0"
            >关闭认证申请</el-radio
          >
          <div class="form-tip">
            <span
              class="el-icon-info"
              style="font-size: 15px; color: #c0c4cc"
            ></span>
            <span>开启后允许用户提交认证申请</span>
          </div>
        </el-form-item>
        <template
          v-if="
            tenantVersion !== 'school' &&
            frameForm.is_open_join_template &&
            roleSetting.personal_user_enable &&
            roleSetting.unit_user_enable
          "
        >
          <el-form-item label="入会限制：" required prop="is_user_apply">
            <el-checkbox
              v-model="frameForm.is_user_apply"
              :disabled="!frameForm.is_unit_apply"
              label="允许个人入会"
              :true-label="1"
              :false-label="0"
            ></el-checkbox>
            <el-checkbox
              v-model="frameForm.is_unit_apply"
              :disabled="!frameForm.is_user_apply"
              label="允许单位入会"
              :true-label="1"
              :false-label="0"
            ></el-checkbox>
          </el-form-item>
        </template>
        <!-- <el-form-item
          v-if="tenantVersion !== 'school'"
          label="阅读章程内容："
          prop="is_open_regulation"
        >
          <el-radio v-model="frameForm.is_open_regulation" :label="1"
            >开启</el-radio
          >
          <el-radio v-model="frameForm.is_open_regulation" :label="0"
            >关闭</el-radio
          >
          <div class="form-tip">
            <span
              class="el-icon-info"
              style="font-size: 15px; color: #c0c4cc"
            ></span>
            <span>开启后,用户需要阅读章程内容才可以填写入会申请</span>
          </div>
        </el-form-item>
        <el-form-item v-else label="内容阅读：" prop="is_open_regulation">
          <el-radio v-model="frameForm.is_open_regulation" :label="1"
            >开启</el-radio
          >
          <el-radio v-model="frameForm.is_open_regulation" :label="0"
            >关闭</el-radio
          >
          <div class="form-tip">
            <span
              class="el-icon-info"
              style="font-size: 15px; color: #c0c4cc"
            ></span>
            <span>开启后用户需要阅读内容才可以进入提交认证</span>
          </div>
        </el-form-item>
        <el-form-item
          v-if="frameForm.is_open_regulation && tenantVersion !== 'school'"
          label="章程页面链接："
          prop="jump_type"
        >
          <el-button
            class="jump_link"
            type="default"
            plain
            @click="showLinkConfigDialog = true"
          >
            {{
              frameForm.regulation_jump_link
                ? frameForm.regulation_jump_link.jump_config.name
                : "设置链接地址"
            }}
          </el-button>
          <div class="form-tip">
            <span
              class="el-icon-info"
              style="font-size: 15px; color: #c0c4cc"
            ></span>
            <span>链接前,请先前往"单页面管理"配置页面内容</span>
          </div>
          <el-input v-show="false" v-model="frameForm.jump_type"> </el-input>
        </el-form-item>
        <el-form-item
          v-if="frameForm.is_open_regulation && tenantVersion === 'school'"
          label="页面链接："
          prop="jump_type"
        >
        
          <el-button
            class="jump_link"
            type="default"
            plain
            @click="showLinkConfigDialog = true"
          >
            {{
              frameForm.regulation_jump_link
                ? frameForm.regulation_jump_link.jump_config.name
                : "设置链接地址"
            }}
          </el-button>
          <div class="form-tip">
            <span
              class="el-icon-info"
              style="font-size: 15px; color: #c0c4cc"
            ></span>
            <span>链接前,请先前往"单页面管理"配置页面内容</span>
          </div>
          <el-input v-show="false" v-model="frameForm.jump_type"> </el-input>
        </el-form-item> -->
      </el-form>
    </template>
    <!-- 入会须知 -->
    <template v-if="stepStatus === 1">
      <el-form
        size="medium"
        :model="noticeForm.instructions_config"
        :rules="noticeRules"
        label-position="right"
        label-width="105px"
        class="small-form"
        ref="noticeForm"
        v-if="noticeForm"
      >
        <el-form-item label="入会须知：">
          <el-radio-group v-model="noticeForm.is_open_instructions">
            <el-radio :label="1">开启</el-radio>
            <el-radio :label="0">关闭</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="noticeForm.is_open_instructions">
          <el-form-item label="页面标题：" prop="title">
            <el-input
              type="text"
              v-model="noticeForm.instructions_config.title"
              placeholder="请输入标题"
            ></el-input>
          </el-form-item>
          <el-form-item label="页面内容：" prop="contents">
            <NoticeContent
              v-model="noticeForm.instructions_config.contents"
              :organization_id="noticeForm.id"
            ></NoticeContent>
          </el-form-item>
          <el-form-item label="logo：" prop="logo">
            <SingleMediaWall
              v-model="noticeForm.instructions_config.logo"
              :width="185"
              :height="135"
              ratio="182:66"
            >
              <p slot="info" class="img-tips">尺寸推荐：182 * 66</p>
            </SingleMediaWall>
          </el-form-item>
          <el-form-item label="背景图：" prop="background">
            <SingleMediaWall
              v-model="noticeForm.instructions_config.background"
              :width="185"
              :height="135"
            >
              <p slot="info" class="img-tips">尺寸推荐：375 * 280</p>
            </SingleMediaWall>
          </el-form-item>
        </template>
      </el-form>
    </template>
    <link-config-dialog
      v-model="showLinkConfigDialog"
      :requestType="['content_link']"
      @change="linkChange"
    />
    <FixedActionBar>
      <el-button @click="cancle">取消</el-button>
      <el-button
        v-if="!isEdit && stepStatus !== 0"
        type="primary"
        @click="stepStatus--"
        :loading="saveLoading"
        >上一步</el-button
      >
      <el-button
        v-if="stepStatus != onLine.length - 1"
        type="primary"
        @click="goNext('frameForm')"
        :loading="saveLoading"
        >{{ isEdit ? "保存" : "下一步" }}</el-button
      >
      <el-button
        v-if="stepStatus == onLine.length - 1"
        type="primary"
        @click="saveNotice('noticeForm')"
        :loading="saveLoading"
        >保存</el-button
      >
    </FixedActionBar>
  </div>
</template>

<script>
import SingleMediaWall from "@/modules/common/components/SingleMediaWall.vue";
import LinkConfigDialog from "../../../app-design/components/Normal/LinkConfigDialog.vue";
import {
  OrganizationDetail,
  ParentList,
  SaveOrganization,
  JoinModules,
  getRoleSetting,
  saveInstructions,
  instructionsDetail,
  saveContent,
  contentDetail,
  positionContentDetail,
  savePositionContent,
} from "../../api/org-frame/add-frame";
import FixedActionBar from "@/base/layout/FixedActionBar";
//  须知内容
import NoticeContent from "../../components/OrgFrame/NoticeContent";
export default {
  data() {
    return {
      loading: false,
      saveLoading: false,
      isEdit: false,
      // 架构信息表单
      frameForm: {
        id: 0,
        name: "",
        pid: "",
        join_organization_id: "",
        icon: "",
        description: "",
        ids: [], // 联级选择id
        is_open_join_template: 0,
        is_open_regulation: 0,
        regulation_jump_link: "",
        jump_type: "",
        is_user_apply: 1,
        is_unit_apply: 1,
      },
      // 须知表单
      noticeForm: {
        id: 0,
        is_open_instructions: 1,
        instructions_config: {
          logo: "",
          title: "",
          background: "",
          contents: [],
        },
      },
      parentOptions: [], // 上级组织列表
      rules: {
        name: [{ required: true, message: "请输入架构名称", trigger: "blur" }],
        ids: [{ required: true, validator: this.pidPass, trigger: "change" }],
        icon: [{ required: true, validator: this.iconPass, trigger: "change" }],
        description: [
          { required: true, message: "请输入描述", trigger: "blur" },
        ],
        jump_type: [
          {
            required: true,
            message: "请选择页面链接",
            trigger: "change",
          },
        ],
        is_user_apply: [{ required: true, message: "", trigger: "change" }],
        is_open_join_template: [
          { required: true, message: "", trigger: "change" },
        ],
      },
      noticeRules: {
        title: [
          { required: true, message: "请输入页面标题", trigger: "blur" },
        ],
        logo: [{ required: true, message: "请选择logo", trigger: "blur" }],
        background: [
          { required: true, message: "请选择背景图", trigger: "blur" },
        ],
        contents: [
          { required: true, message: "请添加页面内容", trigger: "blur" },
        ],
      },
      showLinkConfigDialog: false,
      showImageSelect: false,
      roleSetting: {
        personal_user_enable: 0,
        unit_user_enable: 0,
      },
      // 步骤条
      stepStatus: 0,
      // 步骤条tab
      onLine: [
        {
          name: "架构信息",
        },
        {
          name: "入会须知",
        },
      ],
    };
  },
  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
  },
  methods: {
    // 获取架构详情
    getOrganizationDetail(id) {
      this.loading = true;
      OrganizationDetail(id)
        .then((res) => {
          this.frameForm.jump_type = res.data.regulation_jump_link
            ? res.data.regulation_jump_link.jump_type
            : "";
          this.frameForm = { ...this.frameForm, ...res.data };
          this.getParentList(); // 获取上级组织列表
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 获取入会须知详情
    getInstructionsDetail(cb) {
      this.loading = true;

      instructionsDetail({ id: this.noticeForm.id })
        .then((res) => {
          this.noticeForm = res.data;
          this.loading = false;
          cb && cb();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 获取上级组织列表
    getParentList() {
      ParentList({ is_show_none: 1, is_all: 1, id: this.frameForm.id })
        .then((res) => {
          this.parentOptions = res.data;
        })
        .catch((err) => {});
    },
    // 获取申请模板信息
    // getJoinModules(id) {
    //   JoinModules(id)
    //     .then((res) => {
    //       if (!this.frameForm.configList.length) {
    //         const { data } = res;
    //         data.list.forEach((item) => {
    //           item.is_show = !!item.is_show;
    //           item.is_must = !!item.is_must;
    //         });
    //         this.frameForm.configList = data.list;
    //         this.frameForm.is_open_join_template = data.open;
    //       }
    //       this.saveLoading = false;
    //       const formData = { ...this.frameForm };
    //       this.$router.push({
    //         name: "AddTemplate",
    //         query: {
    //           type: this.type,
    //           id: formData.id,
    //           routeData: this.$enCode(formData),
    //         },
    //       });
    //     })
    //     .catch((err) => {
    //       this.saveLoading = false;
    //     });
    // },
    // 选择图标操作
    iconSelected(e) {
      if (e.length) {
        this.frameForm.icon = e[0].url;
      }
    },
    handleExportCatalogChange() {
      this.$refs.CascaderRef.dropDownVisible = false;
    },
    // 选择跳转链接后
    linkChange(val) {
      this.frameForm.regulation_jump_link = val;
      this.frameForm.jump_type = val.jump_type;
    },
    // 上级组织验证
    pidPass(rule, value, callback) {
      if ((value != undefined && value.length) || this.frameForm.pname) {
        callback();
      } else {
        callback(callback(new Error("请选择上级架构")));
      }
    },
    //图标上传验证
    iconPass(rule, value, callback) {
      if (value) {
        callback();
      } else {
        callback(callback(new Error("请上传图标")));
      }
    },
    // 保存架构信息
    goNext(keyWords) {
      this.$refs[keyWords].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          if (this.frameForm.ids.length) {
            this.frameForm.join_organization_id = this.frameForm.ids[0];
            this.frameForm.pid =
              this.frameForm.ids[this.frameForm.ids.length - 1];
          }
          SaveOrganization(this.frameForm)
            .then((res) => {
              this.$message.success(res.msg);
              // this.$router.push({ name: "CycOrganizationList" });
              // this.frameForm.id = res.data;
              // this.getJoinModules({ organization_id: res.data });
              if (!this.isEdit) {
                this.frameForm.id = res.data;
                this.noticeForm.id = res.data;
                // 获取入会须知详情
                this.getInstructionsDetail(() => {
                  // 步骤条加一
                  this.stepStatus++;
                });

                this.saveLoading = false;
              } else {
                // 刷新列表
                this.getOrganizationDetail({ id: this.$route.query.id });
                this.saveLoading = false;

              }
            })
            .catch((err) => {
              this.saveLoading = false;
            });
        } else {
          return false;
        }
      });
    },
    // 保存入会须知
    saveNotice(keyWords) {
      this.$refs[keyWords].validate((valid) => {
        if (valid) {
          console.log(this.noticeForm);
          saveInstructions(this.noticeForm).then((res) => {
            this.$message.success(res.msg);
            // this.$router.push({ name: "CycOrganizationList" });
            // this.frameForm.id = res.data;
            // this.getJoinModules({ organization_id: res.data });
            if (!this.isEdit) {
              this.$router.push({ name: "CycOrganizationList" });

              this.saveLoading = false;
            } else {
              // 刷新列表
              this.getInstructionsDetail();
            }
          });
        } else {
          return false;
        }
      });
    },
    // 点击取消
    cancle() {
      this.$router.push({ name: "CycOrganizationList" });
    },
    // 改变步骤条
    changeStatus(i) {
      if (!this.isEdit) {
        return;
      }
      this.stepStatus = i;
      switch (this.stepStatus) {
        // 架构详情
        case 0:
          this.getOrganizationDetail({ id: this.$route.query.id });
          break;
        // 入会须知
        case 1:
          this.getInstructionsDetail();
          break;
      }
    },
  },
  created() {
    this.isEdit = this.$route.query.type === "edit";

    console.log(this.isEdit);
    if (this.$route.query.id != 0) {
      this.frameForm.id = this.$route.query.id;
      this.noticeForm.id = this.$route.query.id;
      this.getOrganizationDetail({ id: this.$route.query.id });
      // 获取入会须知详情
      this.getInstructionsDetail();
    } else {
      this.getParentList();
    }

    getRoleSetting()
      .then((res) => {
        this.roleSetting = res.data;
      })
      .catch(() => {});
  },
  beforeRouteEnter(to, from, next) {
    const type = to.query.type;
    switch (type) {
      case "add":
        to.meta.title = "新增";
        break;
      case "edit":
        to.meta.title = "编辑";
        break;
    }
    next();
  },
  components: {
    SingleMediaWall,
    FixedActionBar,
    LinkConfigDialog,
    NoticeContent,
  },
};
</script>

<style lang="scss" scoped>
.body {
  position: relative;
  margin-bottom: 50px;

  .footer-button {
    position: absolute;
    bottom: 40px;
    left: 40px;
  }
}
.form-tip {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #606266;
  line-height: 12px;
  margin-top: 6px;
  span + span {
    margin-left: 4px;
  }
}
.image-info {
  width: 100%;
  color: #606266;
  text-align: center;
  font-size: 12px;
  margin-top: 8px;
}
.img-tips {
  text-align: center;
  font-size: 12px;
  color: #c0c4cc;
  line-height: 24px;
}
</style>

<!-- 步骤条样式修改 -->
<style lang="scss" scoped>
.step-box {
  margin-bottom: 20px;
}
.step-icon {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.step-title {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}
::v-deep .is-process {
  color: #3576ff !important;
  border-color: #3576ff !important;
}
::v-deep .el-step__head.is-finish {
  .el-step__line {
    background-color: #3576ff !important;
  }
}
::v-deep .el-step__icon-inner {
  display: none !important;
}

::v-deep .el-steps.el-steps--horizontal {
  .el-step__icon {
    width: 16px !important;
    height: 16px !important;
  }
  .el-step.is-horizontal .el-step__line {
    top: 7px;
  }
  .el-step__head.is-finish {
    .el-step__icon {
      background-color: #3576ff;
    }
  }
}
</style>
